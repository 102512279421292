<template>
  <v-container
    fluid
    tag="section"
  >
    <v-progress-linear
      v-show="progressBar"
      indeterminate
      color="orange"
    />
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
        sm="8"
      >
        <v-img
          src="@/assets/logo.png"
          aspect-ratio="3.5"
          contain
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
        sm="8"
      >
        <v-card
          class="elevation-12"
        >
          <v-toolbar
            color="indigo"
          >
            <v-toolbar-title
              class="white--text"
            >
              Nueva contraseña
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form
              ref="form"
            >
              <v-text-field
                ref="password"
                v-model="password"
                :rules="[() => !!password || 'Este campo es requerido']"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPassword ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                label="Nueva contraseña"
                counter
                required
                @click:append="showPassword = !showPassword"
              />
              <v-text-field
                ref="password_confirmation"
                v-model="password_confirmation"
                :rules="[
                  () => !!password_confirmation || 'Este campo es requerido',
                  (value) => value === password || 'La contraseña de confirmación no coincide'
                ]"
                :append-icon="showPasswordConfirmation ? 'mdi-eye-off' : 'mdi-eye'"
                :type="showPasswordConfirmation ? 'text' : 'password'"
                prepend-icon="mdi-lock"
                label="Confirmar contraseña"
                counter
                required
                @click:append="showPasswordConfirmation = !showPasswordConfirmation"
              />
            </v-form>
          </v-card-text>
          <v-divider class="mt-5" />
          <v-card-actions>
            <v-spacer />
            <v-btn
              align-center
              justify-center
              color="indigo"
              :disabled="!isTokenValid"
              @click="resetPassword"
            >
              Actualizar
            </v-btn>
          </v-card-actions>
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="colorSnak"
          >
            {{ text }}
            <v-btn
              color="black"
              text
              @click="snackbar = false"
            >
              Cerrar
            </v-btn>
          </v-snackbar>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UsuariosApi from '@/services/api/Usuarios'
  export default {
    data () {
      return {
        progressBar: false,
        colorSnak: 'info',
        snackbar: false,
        text: '',
        timeout: 3000,
        showPassword: false,
        showPasswordConfirmation: false,
        isTokenValid: true,
        token: null,
        email: null,
        password: null,
        password_confirmation: null,
        objetoUsuario: null,
      }
    },
    created () {
      this.token = this.$route.params.token
      UsuariosApi.getResetPasswordConfirmation(this.token)
        .then((user) => {
          this.user = user
          this.email = user.email
          this.token = user.token
        })
        .catch((error) => {
          console.log(error)
          this.isTokenValid = false
          this.text = 'El identificador para cambio de contraseña es inválido'
          this.colorSnak = 'error'
          this.snackbar = true
          setTimeout(() => { this.$router.push({ name: 'Login' }) }, 2000)
        })
    },
    methods: {
      resetPassword () {
        if (this.$refs.form.validate()) {
          this.progressBar = true
          this.objetoUsuario = {
            token: this.token,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
          }
          UsuariosApi.updateUserPassword(this.objetoUsuario)
            .then((response) => {
              this.progressBar = false
              this.text = 'Contraseña modificada con éxito'
              this.colorSnak = 'success'
              this.snackbar = true
              setTimeout(() => { this.$router.push({ name: 'Login' }) }, 2000)
            })
            .catch((error) => {
              console.log(error)
              this.isTokenValid = false
              this.progressBar = false
              this.text = 'No ha sido posible actualizar los datos'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },
    },
  }
</script>
